.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swal-button--confirm {
  background: #D93A26;
}

.swal-button--confirm:hover {
  background: #D93A26 !important;
}

.swal-button--cancel {
  background: #D93A26;
  color: white;
}

.swal-button--cancel:hover {
  background: #D93A26 !important;
}


.item{
  height: 40rem;
  min-width: 30rem;
  padding: 2rem;
}

.item img{
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.slider{
  display: flex;
  cursor: grab;
}

.slider-container{
  margin: 0 auto;
  overflow-x: hidden;
}

.custom-text {
  font-size: 14px;
  color: #676767;
}

.custom-input {
  height: 50px;
  background-color: white;
  border: solid 2px #0A1C38;
  border-radius: 10px 0px 0px 10px;
  width: 80%;
  padding-left: 15px;
}

.custom-get {
  height: 50px;
  background-color: #0A1C38;
  border: solid 2px #0A1C38;
  border-radius: 0px 10px 10px 0px;
  width: 20%;
  color: white;
}

.form-container {
  padding-right:3px
}

.custom-label {
  color: #0A1C38;
  font-weight: 400;
  margin-left: 5px;
}

.custom-container {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.custom-image {
  width: 100%;
  height: auto;
  padding: 5px;
}

.custom-overlay {
  max-width: 300px;
  max-height: 300px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: #0A1C38;
  padding: 5px;
}

.custom-container:hover .custom-overlay {
  opacity: 0.6;
  padding:5px;
}

.download-link {
  display: block;
  color: white;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
  margin-top: 100px;
  font-family: 'Roboto';
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center; */
}





.variation-link {
  display: block;
  color: white;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
  margin-top: 30px;
  font-family: 'Roboto';
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center; */
}


.c-container {
  position: relative;
  display: inline-flex;
  padding: 5px;
}

.c-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  
}

.c-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.c-container:hover .c-image {
  opacity: 0.3;
}

.c-container:hover .c-middle {
  opacity: 1;
}

.c-text {
  color: #0A1C38;
  font-size: 16px;
  padding: 16px 32px;
  margin: 5px;
  font-weight: 500;
}

.custom-upload {
  background-color: #fff;
  border: 2px solid #0A1C38;
  border-radius: 10px 0 0 10px;
  height: 50px;
  padding-left: 15px;
  width: 80%;
  padding: 8px;
}

.custom-select {
  border: 1px solid #0A1C38;
  height: 38px !important;
}

.custom-size {
  line-height: 2.25rem;
  border: 1px solid #0A1C38;
  height: 38px !important;
  padding-left: 15px;
}

.custom-req {
  padding-top: 7px;
  height: 100px !important;
  border: 1px solid #0A1C38;
  padding-left: 15px;
}

.custom-button {
  height: 38px;
  background-color: #0A1C38;
  border: solid 2px #0A1C38;
  border-radius: 10px 10px 10px 1opx;
  width: 20%;
  color: white;
}

.selected-div {
  position: absolute;
  right: 15px;
  bottom: 22px;
  background-color: #0A1C38;
  color: white;
  padding: 4px;
  font-size: 12px;
  border-radius: 5px;
}

.custom-attributes {
  height: 36px;
  background-color: #0A1C38;
  border: solid 2px #0A1C38;
  border-radius: 10px 10px 10px 10px;
  color: white;
  float: right;
  padding-left: 15px;
  padding-right: 15px;
}

.thumb-container {
  display: flex;
}
.thumb-container img {
  display: flex;
  margin: 1px;
}

.button-container {
  width: 100%;
}


